<template>
  <div class="main">
    <div class="solution">
      <div class="pageBanner">
        <div class="banner">
          <div class="describe">
            <div class="tit">解决方案<span>/SOLUTION</span></div>
            <div class="txt">
              为客户提供专业的数据处理、技术开发、数据解决方案，是一家集研发、生产、销售于一体的<br />大数据企业。
            </div>
          </div>
        </div>
      </div>
      <div class="serveCont">
        <div class="tabs">
          <ul>
            <li
              v-for="(item, k) in tabsList"
              :key="k"
              :class="activeClass == item.id ? 'active' : ''"
              @click="tabsCli(item)"
            >
              <router-link :to="item.url">{{ item.tit }}</router-link>
            </li>
            <!-- <li><a href="">数据应用研发服务中心</a></li>
                        <li><a href="">业务场景综合服务中心</a></li>
                        <li><a href="">智慧云服务中心</a></li> -->
          </ul>
        </div>
        <div class="tabsDtail">
          <div class="tit">
            <div style="font-size: 18px">业务场景综合应用中心</div>
          </div>
          <div class="busCont">
            <div class="basiTit">
              为各行业提供丰富的资源组合、解析数字化运营，专注具体行业、领域和细分场景中的问题，匹配个性化场景解决方案，彻底解决各行工作流程上的壁垒。
            </div>
            <div class="basicImg">
              <img src="../assets/business1.png" alt="" />
            </div>
            <div class="system">
              <span class="line"></span>
              <div><span class="yy">应用</span></div>
              <div class="appTit textIndent">
                专注具体行业、领域和细分场景中的问题，通过持续收集、分析、挖掘和运营相关数据来固化场景、测试场景的解决方案，再到迭代和优化场景解决方案，最终达到优化工作流程，彻底解决工作壁垒的目的。
              </div>

              <!-- 国家信息安全可靠系统(未变标题版本) -->
              <!-- <div class="fullLoction">
                <div>国家信息化安全可靠系统</div>
                <div>
                  随着信息安全问题日益突出，信息安全已上升至国家战略，自主可控，国产化替代已成为历史趋势。2019年开始我国信息、网安领域企业逐渐发力“安全可靠工程”，安全可靠工程战略意义在于证明我国具有安全可靠关键系统、关键应用及关键软硬件产品的研发集成能力，能够初步实现对国外信息技术产品的全方位替代，在新的历史起点上构建起网络安全的“铜墙铁壁”。在党政办公及国家重要信息系统推进国产化替代，实现安全可靠、自主可控，保障国家信息安全的工作已全面展开。
                </div>
              </div> -->
              <!-- 改变标题版本 -->
              <div style="margin-top:31px">
                <span class="line"></span>
                <div class="yy" style="">国家信息化安全可靠系统</div>
                <div class="appTit textIndent">
                  随着信息安全问题日益突出，信息安全已上升至国家战略，自主可控，国产化替代已成为历史趋势。2019年开始我国信息、网安领域企业逐渐发力“安全可靠工程”，安全可靠工程战略意义在于证明我国具有安全可靠关键系统、关键应用及关键软硬件产品的研发集成能力，能够初步实现对国外信息技术产品的全方位替代，在新的历史起点上构建起网络安全的“铜墙铁壁”。在党政办公及国家重要信息系统推进国产化替代，实现安全可靠、自主可控，保障国家信息安全的工作已全面展开。
                </div>
              </div>
              <!-- 全面国产化（未改变版本） -->
              <!-- <div class="safetySystem">
                <div>全面国产化</div>
                <div>
                  国产OA、国产服务器、国产CPU、国产操作系统、国产中间件、国产数据库、国产应用软件、国产安全产品。
                </div>
                <div class="img">
                  <img src="../assets/business2.png" alt="" />
                </div>
              </div> -->
              <!-- 改变标题版本 -->
              <div class="safetySystem" style="margin-top:31px">
                <span class="line"></span>
                <div class="yy">全面国产化</div>
                <div class="appTit textIndent">
                  国产OA、国产服务器、国产CPU、国产操作系统、国产中间件、国产数据库、国产应用软件、国产安全产品。
                </div>
                <div class="img">
                  <img src="../assets/business2.png" alt="" />
                </div>
              </div>

              <div class="campus">
                 <span class="line"></span>
                <div>平安校园系统建设</div>
                <div class="textIndent">
                  平安校园系统充分利用“人防”、“物防”和“技防”等多种手段，以实现校园的平安、避免各种事故发生为目的的一体化防范体系。
                </div>
                <div class="function">
                  <div>系统功能</div>
                  <ul>
                    <li>
                      <span></span>安全监控、安全预案、视频分析、安全互动；
                    </li>
                    <li>
                      <span></span>安全防护、访客管理、会议签到、安全广播；
                    </li>
                    <li>
                      <span></span>安全教育、安全管理、防患排查、专项检查；
                    </li>
                    <li>
                      <span></span>物联管理、统计分析、安全地图、安全态势；
                    </li>
                  </ul>
                </div>
              </div>

              <div class="alls">
                <span class="line"></span>
                <div>深度场景分析，形成业务闭环</div>
                <div class="textIndent">
                  通过各个安防场景的软件建设以及监控、大屏、智能设备的建设，结合统一的管理体系和机制，形成业务运行闭环。
                </div>
              </div>
              <div class="alls">
                <span class="line"></span>
                <div>关注团队建设，形成有效机制</div>
                <div class="textIndent">
                  借助软硬件系统，全面监控、指挥、调度校园安全工作。充分发挥安防体系中人防作用。
                </div>
              </div>
              <div class="alls">
                <span class="line"></span>
                <div>高度资源整合，构建安防体系</div>
                <div class="textIndent">
                  借助移动端、监控设备、智能化设备、大屏展示等软硬件的集成整合，构建全面的安防网络，形成符合校园安防业务的安防体系，充分发挥安防体系中物防的作用。
                </div>
              </div>
              <div class="alls">
                <span class="line"></span>
                <div>借助科技手段，提升安防等级</div>
                <div class="textIndent">
                  借助移动互联，云服务、大数据、物联网、人脸识别、智能化等技术，深度融入到校园安防的实际业务中，充分发挥安防体系中技防的作用。
                </div>
              </div>
              <!-- 智慧平安校园 -->
              <div class="appTxt" style="margin-top:30px">
                <span class="line"></span>
                <div class="yy">案例</div>
                <div class="appTit">
                  <span>《智慧平安校园》</span><br />
                  &emsp;&emsp;通过人防物防技防实际解决校园安全问题隐患；
                </div>
                <div class="appTit" style="text-indent:2em">用实际成果为中小学人身安全保驾护航；</div>
                <div class="appTit" style="text-indent:2em">进一步提升中小学平安校园建设的等级；</div>
                <div class="appTit" style="text-indent:2em">为全国提供平安校园建设的新标杆。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeClass: 3,
      appDataList: [
        {
          id: 0,
          imgs: require("../assets/app1.png"),
          tit: "档案数字化",
          cont: "以国家档案局发布的《纸质档案数字化技术规范》图像处理检着标准为基础,可赧据客户具体需求针对性调整。",
        },
        {
          id: 1,
          imgs: require("../assets/app2.png"),
          tit: "表单数字化",
          cont: "利用新讯的智能表格识别技术，从各类机打或手写表单的单元格中采集数据，并填入到相应数据库字段中。广泛应用于各类申请表、登记表、申报表、调查问卷、票据、快运单等数据采集业务。",
        },
        {
          id: 2,
          imgs: require("../assets/app3.png"),
          tit: "报纸数字化",
          cont: "全流程系统70余道数据处理工序，对纸质或各类电子格式的报纸内容提供数据化服务，最终以检索系统的形式进行成品交付，其中图像为400dpi以上高清无倾斜无污渍内容，文字错误率控制在万分之一以下，内容标引项最多可达40余类，100%状态下双层PDF反显低于l毫米。",
        },
        {
          id: 3,
          imgs: require("../assets/app4.png"),
          tit: "图书数字化",
          cont: "提供标准差异化的图书扫描加工、精度细分化的图书文本制作、格式多样化的电子书转码加工、以及图书文字摘录等服务。内容可设计纸本简体新书、拥有电子数据的简体新书、港澳台及国外繁体新书、繁体旧书、民国图书、杂志、技术报告、学位论文、影印古籍、珍本古籍、特藏图书、年鉴、地方志等。",
        },
      ],
      tabsList: [
        {
          id: 1,
          url: "/solution/basic_data?tab=1",
          tit: "基础数据处理服务中心",
        },
        {
          id: 2,
          url: "/solution/data_apply?tab=2",
          tit: "数据应用研发服务中心",
        },
        {
          id: 3,
          url: "/solution/business_scenes?tab=3",
          tit: "业务场景综合应用中心",
        },
        {
          id: 4,
          url: "/solution/cloud?tab=4",
          tit: "智慧云服务中心\xa0 \xa0 \xa0 \xa0 \xa0\xa0",
        },
      ],
    };
  },
  methods: {
    tabsCli(id) {
      this.activeClass = id.id;
    },
  },
};
</script>
<style lang="scss">
@import "../scss/solution.scss";
</style>